import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import {
	FileSizePipe,
	LoadingSpinnerComponent
} from '@array-app/frontend/common';
import { ModelType, ProductModel } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';

@Component({
	standalone: true,
	selector: 'app-file-preview',
	imports: [
		TranslateModule,
		CommonModule,
		LoadingSpinnerComponent,
		FileSizePipe,
		MatMenuModule,
		MatTooltipModule
	],
	templateUrl: 'file-preview.component.html',
	styleUrls: ['file-preview.component.scss']
})
export class FilePreviewComponent {
	/**
	 * Information tied to the preview. Only provided if the file has been previewed
	 */
	@Input() value!: ProductModel;

	/**
	 * Invoked when the user opts to preview the file
	 */
	@Output() preview = new EventEmitter<void>();

	/**
	 * Todo: Abstract this out and pull it in here instead
	 */
	previewSupportedFileTypes: (ModelType | undefined)[] = ['universal'];

	/**
	 * `true` if the preview action can be seen and used
	 *
	 * todo: finish babylon implementation
	 */
	get showPreview() {
		return false;
		// return (
		// 	this.value.type ??
		// 	this.previewSupportedFileTypes.includes(this.value.type)
		// );
	}

	onDownload() {
		if (this.value && this.value.URL) {
			const link = document.createElement('a');
			link.download = this.value.name;
			link.href = this.value.URL;
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
			link.remove();
		}
	}

	onPreview() {
		this.preview.emit();
	}
}
