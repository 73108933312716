<div class="info">
	<h3 class="small line-clamp-2">{{ value.name }}</h3>
	<p class="small text-tertiary">{{ value.size | fileSize }}</p>
	<p class="small text-tertiary">
		{{ 'file.version' | translate: { version: value.version } }}
	</p>
</div>
<div class="actions">
	<button
		*ngIf="showPreview"
		class="tertiary icon-only"
		type="button"
		(click)="onPreview()"
		[matTooltip]="'generic.actions.preview' | translate"
	>
		<i class="fa-solid fa-eye"></i>
	</button>
	<button
		class="tertiary icon-only"
		type="button"
		(click)="onDownload()"
		[matTooltip]="'generic.actions.download' | translate"
	>
		<i class="fa-solid fa-download"></i>
	</button>
</div>
