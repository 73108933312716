import { CommonModule } from '@angular/common';
import { Component, HostBinding, Inject } from '@angular/core';
import {
	MAT_DIALOG_DATA,
	MatDialogModule,
	MatDialogRef
} from '@angular/material/dialog';
import { Event } from '@angular/router';
import { LoadingSpinnerComponent } from '@array-app/frontend/common';
import { DialogRole } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';
import {
	ImageCroppedEvent,
	ImageCropperModule,
	OutputFormat
} from 'ngx-image-cropper';

@Component({
	standalone: true,
	selector: 'app-image-cropper-dialog',
	imports: [
		LoadingSpinnerComponent,
		TranslateModule,
		CommonModule,
		MatDialogModule,
		ImageCropperModule
	],
	templateUrl: 'image-cropper-dialog.component.html',
	styleUrls: ['image-cropper-dialog.component.scss']
})
export class ImageCropperDialogComponent {
	/**
	 * `true` if the image is being loaded. Will apply `loading` class
	 * if `true`.
	 */
	@HostBinding('class.loading')
	loading = true;
	/**
	 * The saved image event created from the component.
	 */
	croppedImageEvent!: ImageCroppedEvent;
	/**
	 * `true` if there was an error with the image cropper
	 */
	error = false;

	constructor(
		private readonly dialogRef: MatDialogRef<ImageCropperDialogComponent>,
		@Inject(MAT_DIALOG_DATA)
		public readonly data: {
			event: Event;
			format: OutputFormat;
			aspectRatio: number;
		}
	) {}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImageEvent = event;
	}

	imageLoaded() {
		setTimeout(() => {
			this.loading = false;
		}, 500);
	}

	imageLoadFailed() {
		this.loading = false;
		this.error = true;
	}

	cancel() {
		this.dialogRef.close({ role: DialogRole.Cancel, data: null });
	}

	save() {
		if (!this.croppedImageEvent?.base64) {
			console.warn('No cropped image data found');
			return;
		}
		this.dialogRef.close({
			role: DialogRole.Submit,
			data: {
				base64: this.croppedImageEvent.base64
			}
		});
	}
}
