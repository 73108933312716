import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { AuthService } from '@array-app/frontend/authentication';
import { Organization } from '@array-app/shared/types';
import { OrganizationApiService } from '../services';

@Injectable({
	providedIn: 'root'
})
export class OrganizationDetailResolve {
	constructor(
		private readonly authService: AuthService,
		private readonly organizationAdminService: OrganizationApiService
	) {}

	async resolve(route: ActivatedRouteSnapshot) {
		let organization = this.authService.organization$.value;
		const id = route.params['id'];
		if (id) {
			organization = await this.organizationAdminService
				.findById(id)
				.then((doc) => doc.data() as Organization);
		}

		return organization;
	}
}
