import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import {
	CollectionReference,
	Firestore,
	Timestamp,
	UpdateData,
	collection,
	doc,
	query,
	updateDoc,
	where
} from '@angular/fire/firestore';
import { AuthService } from '@array-app/frontend/authentication';
import { BaseApiService } from '@array-app/frontend/common';
import { Organization } from '@array-app/shared/types';
import { sanitize } from '@array-app/shared/utils';

@Injectable({
	providedIn: 'root'
})
export class OrganizationApiService extends BaseApiService<Organization> {
	override get url() {
		return `${this.baseUrl}/v1/organizations`;
	}

	constructor(
		protected override readonly firestore: Firestore,
		protected override readonly authService: AuthService,
		protected override readonly injector: Injector,
		private readonly http: HttpClient
	) {
		super(firestore, authService, injector, 'organizations');
	}

	/**
	 * Initializes the firestore service
	 */
	override initialize() {
		this.collection = collection(
			this.firestore,
			'organizations'
		) as CollectionReference<Organization, Organization>;
		this.collectionQuery = query(
			this.collection,
			where('deletedAt', '==', null)
		);
	}

	/**
	 * Creates a new organization within the system
	 *
	 * @param data The data to create a new organization with
	 * @returns The newly created organization
	 */
	override create$(data: Partial<Organization>) {
		return this.http.post<Organization>(`${this.url}`, data);
	}

	/**
	 * Updates the corresponding organization with the newly passed through data.
	 *
	 * @param data The data to use to update the corresponding organization with
	 * @returns The newly updated organization
	 */
	override async update(data: UpdateData<Partial<Organization>>) {
		data.updatedAt = Timestamp.now();
		try {
			const ref = doc(this.collection, data.id as string);
			await updateDoc(ref, sanitize(data));
			if (data.id === this.authService.organization$.value?.id) {
				const org = (await this.findById(data.id as string)).data();
				this.authService.organization$.next(org);
			}
			return ref;
		} catch (error) {
			console.error(error);
			throw new Error(
				'There was an issue updating the organization in the Firestore'
			);
		}
	}
}
