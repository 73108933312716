<div class="upload-container">
	<ng-container *ngIf="!draggingOver && !value">
		<div class="drop-or-browse">
			<p>{{ 'file.upload.drop-or-browse' | translate }}</p>
		</div>
	</ng-container>

	<ng-container *ngIf="value">
		<app-image [showIcon]="false" [src]="value"></app-image>
		<div class="actions">
			<button
				class="tertiary icon-only"
				type="button"
				[matMenuTriggerFor]="menu"
			>
				<i class="fa-solid fa-ellipsis-vertical"></i>
			</button>
		</div>
	</ng-container>

	<ng-container *ngIf="draggingOver">
		<div class="drop-to-add">
			<p>{{ 'file.upload.drop-to-add' | translate }}</p>
		</div>
	</ng-container>

	<ng-container *ngIf="!draggingOver">
		<div class="click-to-browse">
			<p class="click-to-browse">
				{{ 'file.upload.click-to-browse' | translate }}
			</p>
		</div>
	</ng-container>

	<input
		#inputRef
		type="file"
		accept="image/*"
		[multiple]="false"
		name="image_upload"
		(change)="onFileChangeEvent($event)"
	/>

	<ng-container *ngIf="busy">
		<div class="busy-container">
			<app-loading-spinner></app-loading-spinner>
		</div>
	</ng-container>
</div>

<p class="xsmall text-tertiary margin-1">
	{{ 'form.shared.accepts' | translate: { extensions: supportedTypes } }}
</p>

<mat-menu #menu="matMenu">
	<ng-template matMenuContent>
		<button mat-menu-item class="danger" (click)="onRemove()">
			<i class="fa-solid fa-trash"></i>
			{{ 'form.actions.remove' | translate }}
		</button>
	</ng-template>
</mat-menu>
