import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input } from '@angular/core';

@Component({
	standalone: true,
	selector: 'app-image',
	imports: [CommonModule],
	templateUrl: 'image.component.html',
	styleUrls: ['image.component.scss']
})
export class ImageComponent {
	/**
	 * The src of the image to load
	 */
	@HostBinding('class.has-src')
	@Input()
	src: string | undefined;
	/**
	 * `true` if the icon should show when the image source is empty
	 */
	@Input() showIcon = true;
	/**
	 * The icon to display if the image source is empty.
	 */
	@Input() emptyIcon = 'fa-solid fa-image';
	/**
	 * The preset size of the image to load
	 */
	@Input() size: 'xsmall' | 'small' | 'medium' | 'large' = 'medium';
	/**
	 * The type of image being rendered. Determines other visual aspects
	 */
	@Input() type: 'square' | 'rect' | 'wordmark' | 'circle' = 'square';

	@HostBinding('class')
	get classNames() {
		return `${this.size} ${this.type}`;
	}
}
