<div class="upload-container">
	<ng-container *ngIf="!draggingOver && !(value || uploadData?.URL)">
		<div class="drop-or-browse">
			<p>{{ 'file.upload.drop-or-browse' | translate }}</p>
		</div>
	</ng-container>

	<ng-container *ngIf="value || uploadData?.URL">
		<div class="info">
			<ng-container *ngIf="value">
				<div>
					<h3 class="small line-clamp-2">{{ value.name }}</h3>
					<p class="small text-tertiary">
						{{ value.size | fileSize }}
					</p>
				</div>
				<app-text-badge type="caution">
					{{ 'file.not-uploaded' | translate }}
				</app-text-badge>
			</ng-container>
			<ng-container *ngIf="!value && uploadData?.URL">
				<div>
					<h3 class="small line-clamp-2">{{ uploadData.name }}</h3>
					<p class="small text-tertiary">
						{{ uploadData.size | fileSize }}
					</p>
					<p class="small text-tertiary">
						{{
							'file.version'
								| translate: { version: uploadData.version }
						}}
					</p>
				</div>
				<app-text-badge type="success">
					{{ 'file.uploaded' | translate }}
				</app-text-badge>
			</ng-container>
		</div>
		<div class="actions">
			<button
				class="tertiary icon-only"
				type="button"
				[matMenuTriggerFor]="menu"
			>
				<i class="fa-solid fa-ellipsis-vertical"></i>
			</button>
		</div>
	</ng-container>

	<ng-container *ngIf="draggingOver">
		<div class="drop-to-add">
			<p>{{ 'file.upload.drop-to-add' | translate }}</p>
		</div>
	</ng-container>

	<ng-container *ngIf="!draggingOver">
		<div class="click-to-browse">
			<p class="click-to-browse">
				{{ 'file.upload.click-to-browse' | translate }}
			</p>
		</div>
	</ng-container>

	<input
		#inputRef
		type="file"
		[accept]="supportedTypes.join(', ')"
		[multiple]="false"
		name="file_upload"
		(change)="onFileChangeEvent($event)"
	/>

	<ng-container *ngIf="busy">
		<div class="busy-container">
			<app-loading-spinner></app-loading-spinner>
		</div>
	</ng-container>
</div>

<p class="xsmall text-tertiary margin-1">
	{{
		'form.shared.accepts'
			| translate: { extensions: supportedTypes.join(', ') }
	}}
</p>

<mat-menu #menu="matMenu">
	<ng-template matMenuContent>
		<button mat-menu-item class="danger" (click)="onRemove()">
			<i class="fa-solid fa-trash"></i>
			{{ 'form.actions.remove' | translate }}
		</button>
	</ng-template>
</mat-menu>
