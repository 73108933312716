import { CommonModule } from '@angular/common';
import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output,
	ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import {
	AcknowledgeDialogComponent,
	FileSizePipe,
	LoadingSpinnerComponent,
	TextBadgeComponent
} from '@array-app/frontend/common';
import { FileType } from '@array-app/shared/types';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs';

@Component({
	standalone: true,
	selector: 'app-file-upload',
	imports: [
		TranslateModule,
		CommonModule,
		LoadingSpinnerComponent,
		MatMenuModule,
		FileSizePipe,
		TextBadgeComponent
	],
	templateUrl: 'file-upload.component.html',
	styleUrls: ['file-upload.component.scss']
})
export class FileUploadComponent {
	/**
	 * Reference to the input in the component
	 */
	@ViewChild('inputRef') inputRef!: ElementRef<HTMLInputElement>;
	/**
	 * The file source to display. Either the file itself or the location of where its hosted
	 */
	@Input() value!: File | null;
	/**
	 * Information tied to the upload. Only provided if the file has been uploaded
	 */
	@Input() uploadData!: {
		URL: string;
		name: string;
		size: number;
		version: number;
	};
	/**
	 * The aspect ratio of the file when being cropped
	 */
	@Input() aspectRatio = 1;
	/**
	 * List of supported file file types
	 */
	@Input() supportedTypes: FileType[] = ['.unity3d'];
	/**
	 * Emits when the file is changed after the cropper
	 */
	@Output() valueChange = new EventEmitter<File | null>();
	/**
	 * `true` if the user is dragging a file over the component
	 */
	@HostBinding('class.dragging-over') draggingOver = false;
	/**
	 * `true` if the file cropper or additional options have appeared
	 */
	@HostBinding('class.busy') busy = false;

	constructor(
		private readonly dialog: MatDialog,
		private readonly translate: TranslateService
	) {}

	async onFileChangeEvent(event: any) {
		this.draggingOver = false;

		const file = this.inputRef.nativeElement.files
			? this.inputRef.nativeElement.files[0]
			: null;

		if (!event?.target?.value || !file) {
			return;
		}
		this.busy = true;

		const fileSplit = file.name.toLowerCase().trim().split('.');
		const extension = `.${fileSplit[fileSplit.length - 1]}` as FileType;

		if (!this.supportedTypes.includes(extension)) {
			console.error('This file type is not supported');
			const dialog = this.dialog.open(AcknowledgeDialogComponent, {
				panelClass: 'small',
				data: {
					header: this.translate.instant(
						'form.shared.dialogs.unsupported-file-type.header'
					),
					message: this.translate.instant(
						'form.shared.dialogs.unsupported-file-type.message'
					)
				}
			});

			dialog
				.afterClosed()
				.pipe(take(1))
				.subscribe(() => (this.busy = false));
			return;
		}

		this.value = file;
		this.valueChange.emit(file);
		this.busy = false;
	}

	/**
	 * Removes the current file from the input
	 */
	onRemove() {
		this.inputRef.nativeElement.value = '';
		this.value = null;
		this.valueChange.emit(null);
	}

	@HostListener('dragover')
	onDragOver() {
		this.draggingOver = true;
	}

	@HostListener('dragleave')
	opnDragLeave() {
		this.draggingOver = false;
	}

	@HostListener('mouseleave')
	opnMouseLeave() {
		this.draggingOver = false;
	}
}
