<h1 mat-dialog-title>{{ 'file.image.crop.header' | translate }}</h1>

<div mat-dialog-content>
	<ng-container *ngIf="data.event">
		<image-cropper
			*ngIf="data.event && !error"
			[format]="data.format || 'jpeg'"
			[aspectRatio]="data.aspectRatio || 1"
			[maintainAspectRatio]="true"
			[imageChangedEvent]="data.event"
			(imageCropped)="imageCropped($event)"
			(imageLoaded)="imageLoaded()"
			(loadImageFailed)="imageLoadFailed()"
		></image-cropper>
		<ng-container *ngIf="loading">
			<div class="loader">
				<app-loading-spinner></app-loading-spinner>
			</div>
		</ng-container>
		<ng-container *ngIf="error">
			<p>
				{{
					'form.shared.dialogs.unsupported-file-type.message'
						| translate
				}}
			</p>
		</ng-container>
	</ng-container>
	<ng-container *ngIf="!data.event">
		<p>{{ 'file.image.crop.not-found' | translate }}</p>
	</ng-container>
</div>

<div mat-dialog-actions>
	<button class="icon" (click)="cancel()">
		<i class="fa-solid fa-xmark"></i>
		{{ 'form.actions.cancel' | translate }}
	</button>
	<button
		class="icon primary"
		[disabled]="!data.event || !croppedImageEvent"
		(click)="save()"
	>
		<i class="fa-solid fa-floppy-disk"></i>
		{{ 'form.actions.save' | translate }}
	</button>
</div>
