import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from '@array-app/frontend/authentication';
import { LoadingSpinnerComponent } from '@array-app/frontend/common';
import { DialogRole, Organization } from '@array-app/shared/types';
import { TranslateModule } from '@ngx-translate/core';
import { OrganizationLogoComponent } from '../../components';
import { OrganizationApiService } from '../../services';

@Component({
	standalone: true,
	selector: 'app-switch-organization-dialog',
	imports: [
		TranslateModule,
		CommonModule,
		MatDialogModule,
		LoadingSpinnerComponent,
		OrganizationLogoComponent
	],
	templateUrl: 'switch-organization.component.html',
	styleUrls: ['switch-organization.component.scss']
})
export class SwitchOrganizationDialogComponent implements OnInit {
	/**
	 * The current organization the auth user is signed into
	 */
	currentOrganization: Organization | undefined;
	/**
	 * The available organizations to switch to
	 */
	organizations: Organization[] = [];
	/**
	 * `true` if loading something
	 */
	busy = false;

	constructor(
		private readonly dialogRef: MatDialogRef<SwitchOrganizationDialogComponent>,
		private readonly authService: AuthService,
		private readonly organizationService: OrganizationApiService
	) {}

	ngOnInit() {
		this.busy = true;
		const authUser = this.authService.authUser$.value;
		this.currentOrganization = this.authService.organization$?.value;

		if (authUser) {
			const ids = authUser.users
				.filter((user) => !user.deletedAt)
				.map((user) => user.organizationId);

			this.organizationService.findByIds(ids).then((snapshots) => {
				this.organizations = snapshots.docs.map((d) => d.data());
				this.busy = false;
			});
		} else {
			this.onCancel();
		}
	}

	onOrganizationSelect(organization: Organization) {
		this.dialogRef.close({
			role: DialogRole.Submit,
			data: {
				organization
			}
		});
	}

	onCancel() {
		this.dialogRef.close({ role: DialogRole.Cancel });
	}
}
