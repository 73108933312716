import { Component, HostBinding, Input } from '@angular/core';
import { ImageComponent } from '@array-app/frontend/files';
import { Organization } from '@array-app/shared/types';
import { getDisplayTheme } from '@array-app/shared/utils';

@Component({
	standalone: true,
	selector: 'app-organization-logo',
	imports: [ImageComponent],
	templateUrl: 'organization-logo.component.html',
	styleUrls: ['organization-logo.component.scss']
})
export class OrganizationLogoComponent {
	@Input() organization!: Organization;

	@Input() size: 'small' | 'medium' | 'large' = 'medium';

	@HostBinding('style.background-color')
	get backgroundColor() {
		return this.organization[this.theme].secondaryColor;
	}

	get theme() {
		return getDisplayTheme();
	}
}
