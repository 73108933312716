import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { AuthService } from '@array-app/frontend/authentication';
import { PageTitleResolve } from '@array-app/frontend/common';
import { Organization } from '@array-app/shared/types';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationApiService } from '../services';

@Injectable({ providedIn: 'root' })
export class OrganizationPageTitleResolve extends PageTitleResolve {
	constructor(
		protected override readonly translate: TranslateService,
		private readonly organizationAdminService: OrganizationApiService,
		private readonly authService: AuthService,
		private readonly router: Router
	) {
		super(translate);
	}

	override async resolve(route: ActivatedRouteSnapshot) {
		const organizationId = route.params['id'];
		const organization = organizationId
			? await this.organizationAdminService
					.findById(organizationId)
					.then((res) => res.data() as Organization)
			: this.authService.organization$.value;

		if (!organization) {
			this.router.navigate(['app/users']);
			return '';
		}

		document.title =
			this.pageTitleBase +
			this.translate.instant('route.title.organizations') +
			' | ' +
			(organization?.name ??
				this.translate.instant('route.title.organization'));
		return document.title;
	}
}
