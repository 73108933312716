import { CommonModule } from '@angular/common';
import {
	Component,
	ElementRef,
	EventEmitter,
	HostBinding,
	HostListener,
	Input,
	Output,
	ViewChild
} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuModule } from '@angular/material/menu';
import {
	AcknowledgeDialogComponent,
	LoadingSpinnerComponent
} from '@array-app/frontend/common';
import { DialogAction, DialogRole } from '@array-app/shared/types';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ImageCropperModule, OutputFormat } from 'ngx-image-cropper';
import { take } from 'rxjs';
import { ImageCropperDialogComponent } from '../../dialogs';
import { ImageComponent } from '../image/image.component';
import { SupportedImageFileTypes } from './../../data/image-files.const';

@Component({
	standalone: true,
	selector: 'app-image-upload',
	imports: [
		TranslateModule,
		CommonModule,
		ImageCropperModule,
		ImageCropperDialogComponent,
		ImageComponent,
		LoadingSpinnerComponent,
		MatMenuModule
	],
	templateUrl: 'image-upload.component.html',
	styleUrls: ['image-upload.component.scss']
})
export class ImageUploadComponent {
	/**
	 * Reference to the input in the component
	 */
	@ViewChild('inputRef') inputRef!: ElementRef<HTMLInputElement>;
	/**
	 * The image source to display
	 */
	@Input() value!: string;
	/**
	 * The format of the image after its cropped
	 */
	@Input() imageFormat: OutputFormat = 'jpeg';
	/**
	 * The aspect ratio of the image when being cropped
	 */
	@Input() aspectRatio = 1;
	/**
	 * Emits when the image is changed after the cropper
	 */
	@Output() valueChange = new EventEmitter<string>();
	/**
	 * `true` if the user is dragging a file over the component
	 */
	@HostBinding('class.dragging-over') draggingOver = false;
	/**
	 * `true` if the image cropper or additional options have appeared
	 */
	@HostBinding('class.busy') busy = false;
	/**
	 * List of supported image file types
	 */
	supportedTypes = SupportedImageFileTypes.join(', ');

	constructor(
		private readonly dialog: MatDialog,
		private readonly translate: TranslateService
	) {}

	async onFileChangeEvent(event: Event) {
		this.draggingOver = false;

		const file = this.inputRef.nativeElement.files
			? this.inputRef.nativeElement.files[0]
			: null;

		if (!(event.target as HTMLInputElement)?.value || !file) {
			return;
		}

		if (!file.type.startsWith('image/')) {
			console.error('This file type is not supported');
			this.dialog.open(AcknowledgeDialogComponent, {
				panelClass: 'small',
				data: {
					header: this.translate.instant(
						'form.shared.dialogs.unsupported-file-type.header'
					),
					message: this.translate.instant(
						'form.shared.dialogs.unsupported-file-type.message'
					)
				}
			});
			return;
		}

		this.busy = true;
		const dialog = this.dialog.open(ImageCropperDialogComponent, {
			data: {
				event,
				format: this.imageFormat,
				aspectRatio: this.aspectRatio
			}
		});

		dialog
			.afterClosed()
			.pipe(take(1))
			.subscribe((res: DialogAction) => {
				if (res?.role) {
					switch (res.role) {
						case DialogRole.Cancel:
							this.inputRef.nativeElement.value = '';
							break;
						case DialogRole.Submit:
							this.value = res.data.base64;
							this.valueChange.emit(res.data.base64);
							break;
					}
				}

				this.busy = false;
			});
	}

	/**
	 * Removes the current image from the input
	 */
	onRemove() {
		this.inputRef.nativeElement.value = '';
		this.value = '';
		this.valueChange.emit('');
	}

	@HostListener('dragover')
	onDragOver() {
		this.draggingOver = true;
	}

	@HostListener('dragleave')
	opnDragLeave() {
		this.draggingOver = false;
	}

	@HostListener('mouseleave')
	opnMouseLeave() {
		this.draggingOver = false;
	}
}
