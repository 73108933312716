import { Injectable } from '@angular/core';
import {
	Storage,
	deleteObject,
	getDownloadURL,
	ref,
	uploadBytes
} from '@angular/fire/storage';
import { AuthService } from '@array-app/frontend/authentication';
import { ImageCompressionOptions } from '@array-app/shared/types';
import imageCompression from 'browser-image-compression';

@Injectable({
	providedIn: 'root'
})
export class FileService {
	/**
	 * The id of the organization the user is logged in under
	 */
	private organizationId!: string;

	constructor(
		private readonly storage: Storage,
		private readonly authService: AuthService
	) {
		const org = this.authService.organization$.value;
		if (org) {
			this.organizationId = org.id;
		}
	}

	/**
	 * Uploads the provided file to the angular firebase storage and returns a task to follow
	 * @param file The file to upload to the firebase storage
	 * @param path The path to where the file should be storage AFTER the organization id
	 * @returns A task observable to follow the progress
	 */
	async uploadFile(
		file: File,
		path: string,
		orgId = this.organizationId
	): Promise<string> {
		const storageRef = ref(
			this.storage,
			`${orgId}/${path}${this.getExtension(file)}`
		);
		const task = await uploadBytes(storageRef, file);
		return getDownloadURL(task.ref);
	}

	/**
	 * Uploads the provided file to the angular firebase storage and returns a task to follow
	 * @param file The file to upload to the firebase storage
	 * @param path The path to where the file should be storage AFTER the organization id
	 * @returns A task observable to follow the progress
	 */
	async uploadImage(
		file: File,
		path: string,
		orgId = this.organizationId,
		options?: ImageCompressionOptions
	): Promise<string> {
		const compressedImage = await this.compressImage(file, options);
		return this.uploadFile(compressedImage, path, orgId);
	}

	/**
	 * Deletes the file at the path provided in the Firebase storage
	 * @param path The path of the file to delete
	 * @returns An observable of the task to execute
	 */
	delete(path: string) {
		return deleteObject(
			ref(this.storage, `${this.organizationId}/${path}`)
		).catch();
	}

	/**
	 * Deletes the file located at the provided url if located in firebase
	 * @param url the url to where the file is located
	 * @returns observable task of the deletion
	 */
	deleteFromURL(url: string) {
		return deleteObject(ref(this.storage, url)).catch();
	}

	/**
	 * Compresses the provided image to a more performant storable file.
	 * Prevents users from uploading a very large image into the database.
	 * @param file The file to compress
	 * @returns The newly compressed file
	 */
	compressImage(file: File, options?: ImageCompressionOptions) {
		return imageCompression(file, {
			maxSizeMB: 0.5, // TODO: Make global value
			maxWidthOrHeight: 256, // TODO: Make global value
			...options
		});
	}

	/**
	 * Determines what the extension of the file is and returns it
	 * @param file The file to get the type from
	 * @returns the extension of the file
	 */
	getExtension(file: File) {
		if (file.type) {
			return `.${file.type.split('/')[1]}`;
		} else if (file.name) {
			return `.${file.name.split('.')[1]}`;
		}
		return '.txt';
	}
}
