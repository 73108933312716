<h1 mat-dialog-title>{{ 'users.switch-organization' | translate }}</h1>

<div mat-dialog-content>
	<ng-container *ngIf="!busy; else loader">
		<div *ngFor="let organization of organizations" class="org-item">
			<app-organization-logo
				size="small"
				[organization]="organization"
			></app-organization-logo>

			<div class="info">
				<p
					class="line-clamp-1 primary margin-0 medium font-bold"
					[title]="organization.name"
				>
					{{ organization.name }}
				</p>
			</div>
			<div class="actions">
				<ng-container
					*ngIf="organization.id === currentOrganization?.id"
				>
					<p class="small text-tertiary margin-right-2">Signed in</p>
				</ng-container>
				<ng-container
					*ngIf="organization.id !== currentOrganization?.id"
				>
					<button
						class="icon small secondary"
						(click)="onOrganizationSelect(organization)"
					>
						<i class="fa-solid fa-check-double"></i>
						{{ 'generic.select' | translate }}
					</button>
				</ng-container>
			</div>
		</div>
	</ng-container>
</div>

<div mat-dialog-actions>
	<button class="icon" (click)="onCancel()">
		<i class="fa-solid fa-xmark"></i>
		{{ 'form.actions.cancel' | translate }}
	</button>
</div>

<ng-template #loader>
	<div *ngIf="busy" class="loader">
		<app-loading-spinner></app-loading-spinner>
	</div>
</ng-template>
