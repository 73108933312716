import { Component, HostBinding, Input } from '@angular/core';
import { ImageComponent } from '@array-app/frontend/files';
import { Organization } from '@array-app/shared/types';
import { getDisplayTheme } from '@array-app/shared/utils';

@Component({
	standalone: true,
	selector: 'app-organization-wordmark',
	imports: [ImageComponent],
	templateUrl: 'organization-wordmark.component.html',
	styleUrls: ['organization-wordmark.component.scss']
})
export class OrganizationWordmarkComponent {
	@HostBinding('class')
	@Input()
	size: 'xsmall' | 'small' | 'medium' | 'large' | 'full' = 'medium';

	@Input() organization!: Organization;

	get theme() {
		return getDisplayTheme();
	}

	get imageSize() {
		if (this.size === 'full') {
			return 'medium';
		}
		return this.size;
	}
}
